import { PlainDocument, preload } from '../../../layouts/plain-document';
import type { RouteDefinition } from '@solidjs/router';

export default function TroonExecutiveCardTerms() {
	return (
		<PlainDocument
			breadcrumbs={[{ href: '/card/troon-executive-card', title: 'Troon Executive Card' }]}
			slug="troon-executive-card-terms"
		/>
	);
}

export const route = { preload: preload('troon-executive-card-terms') } satisfies RouteDefinition;
